import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    // {
    //   path: "/",
    //   name: "Home",
    //   component: Home
    // },
    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue")
    // },
    {
        path: "/",
        redirect: "/id/Home",
        hidden: true,
        meta: {
            title: "RHB Warrants - Home",
            content: {
                keywords: "Malaysia warrants, structured warrants, RHB, Structured warrants, Warrants, Warrant, Hong Kong warrants, Search Warrant, Search Underlying, Daily Warrant",
                description: "The one-stop structured warrants portal in Malaysia."
            }
        }
    },
    {
        path: "/id/Document",
        name: "Document",
        component: () =>
            import("../views/Document/Document.vue"),
        meta: {
            title: "Warrant Document",
            content: {
                keywords: "listing, prospectus, term sheets, corporate actions",
                description: "Seluruh dokumentasi akan Waran Terstruktur, seperti: Prospektus, Term Sheet, dan Aksi Korporasi."
            }
        }
    },
    {
        path: "/id/ExpiringWarrants",
        name: "ExpiringWarrants",
        component: () =>
            import("../views/ExpiringWarrants/ExpiringWarrants.vue"),
        meta: {
            title: "Expiring Warrants",
            content: {
                keywords: "expiring, RHB, malaysia warrants, Maturing, RHB Warrant",
                description: "Waran Terstruktur yang telah jatuh tempo dan tidak terdapat lagi didalam Bursa Efek Indonesia untuk diperdagangkan"
            }
        }
    },
    {
        path: "/id/Home",
        name: "Home",
        component: () =>
            import("../views/LandingPage/LandingPage.vue"),
        meta: {
            title: "RHB Warrants - Home",
            content: {
                keywords: "Malaysia warrants, structured warrants, RHB",
                description: "Portal waran terstruktur satu atap di Indonesia."
            }
        }
    },
    {
        path: "/id/LearnWarrants",
        name: "LearnWarrants",
        component: () =>
            import("../views/LearnWarrants/LearnWarrants.vue"),
        meta: {
            title: "Learn Warrants",
            content: {
                keywords: "warrant education, call warrants, structured warrants, warrants FAQ, Learn Warrants, Put Warrants, Trade Warrant, Warrant Lingo, Warrant Risk, Warrant Value, Cash Settlement, Market Makers",
                description: "Pelajari lebih dalam mengenai Waran Terstruktur. Di portal ini investor akan mendapatkan begitu banyak jawaban atas pertanyaan-pertanyaan seputar Waran Terstruktur"
            }
        }
    },
    {
        path: "/id/MarketMovers",
        name: "MarketMovers",
        component: () =>
            import("../views/MarketMovers/MarketMovers.vue"),
        meta: {
            title: "Market Movers",
            content: {
                keywords: "top traded shares, top traded warrants, liquidity, top gainers, top losers, active, top volume, Best warrants",
                description: "Pergerakan indeks pada hari ini banyak dipengaruhi oleh saham-saham"
            }
        }
    },
    // {
    //     path: "/id/HongKongStockWarrants",
    //     name: "HongKongStockWarrants",
    //     component: () =>
    //         import ("../views/HongKongStockWarrants/HongKongStockWarrants.vue"),
    //     meta: {
    //         title: "Hong Kong Stock Warrants",
    //         content: {
    //             keywords: "Hong Kong Stock, Alibaba, Tencent, Meituan, Xiaomi, Hong Kong warrants, ATMX, Hong Kong Market Making Hours, Hang Seng, Hang Seng Tech, Hong Kong Exchange",
    //             description: "Read to learn more about the warrants of the top technology stocks listed in Hong Kong."
    //         }
    //     }
    // },
    {
        path: "/id/NewWarrants",
        name: "NewWarrants",
        component: () =>
            import("../views/NewWarrants/NewWarrants.vue"),
        meta: {
            title: "New Warrants",
            content: {
                keywords: "new issue, IPO, RHB, malaysia warrants, New Listing, New Warrants, RHB Warrant",
                description: "Waran Terstruktur terbaru yang akan/telah diterbitkan oleh PT RHB Sekuritas Indonesia"
            }
        }
    },
    {
        path: "/id/Seminar",
        name: "Seminar",
        component: () =>
            import("../views/Seminar/Seminar.vue"),
        meta: {
            title: "Seminar",
            content: {
                keywords: "call warrants, seminar, malaysia warrants, Webinar, Workshop, Bursa Malaysia",
                description: "Pertemuan tatap muka akan memberikan pemahaman yang lebih baik, khususnya untuk pembelajaran Waran Terstruktur"
            }
        }
    },
    {
        path: "/id/SignMeUp",
        name: "SignMeUp",
        component: () =>
            import("../views/SignMeUp/SignMeUp.vue"),
        meta: {
            title: "Sign me Up",
            content: {
                keywords: "warrant in focus, newsletter, malaysia warrants, subscribe, RHB Warrant, Free, Daily news",
                description: "Daftarkan diri anda untuk berlangganan Warrant in Focus"
            }
        }
    },
    {
        path: "/id/UnderlyingChart",
        name: "UnderlyingChart",
        component: () =>
            import("../views/UnderlyingChart/UnderlyingChart.vue"),
        meta: {
            title: "Underlying Chart",
            content: {
                keywords: "underlying charts, technical analysis, traded volume, Price Chart, Daily Chart, Weekly Chart, Monthly Chart, Yearly Chart, ",
                description: "Setiap Waran Terstruktur akan memiliki saham underlyingnya dan investor harus memperhatikannya sebagai acuan dari arah pergerakan Waran Terstrukturnya"
            }
        }
    },
    {
        path: "/id/WarrantDetails",
        name: "WarrantDetails",
        component: () =>
            import("../views/WarrantDetails/WarrantDetails.vue"),
        meta: {
            title: "Warrant Details",
            content: {
                keywords: "warrant details, warrant terms, RHB, malaysia warrants, Ambank, Kenanga, Macquarie, CIMB, Maybank, Call Warrant, Put Warrant, Chart,",
                description: "Portal pencarian akan detail Waran Terstruktur untuk setiap serinya"
            }
        }
    },
    {
        path: "/id/WarrantInFocus",
        name: "WarrantInFocus",
        component: () =>
            import("../views/WarrantInFocus/WarrantInFocus.vue"),
        meta: {
            title: "Warrants in Focus",
            content: {
                keywords: "daily market highlights, stock market, research, newsletter, warrant market, warrant ideas, stock ideas, RHB Research, RHB warrant, Articles, write ups",
                description: "Warrant in Focus adalah media komunikasi bagi investor untuk mendapatkan berita harian mengenai Waran Terstruktur"
            }
        }
    },
    {
        path: "/id/WarrantMatrix",
        name: "WarrantMatrix",
        component: () =>
            import("../views/WarrantMatrix/WarrantMatrix.vue"),
        meta: {
            title: "Warrant Matrix",
            content: {
                keywords: "price matrix, live matrix, live pricing, bid price, Malaysia warrants, RHB, Warrant Matrix, Ask Price, Hong Kong Warrants, Share Price, Warrant Price, Pricing model",
                description: "Use our live matrix to make trading warrants easy. See where our bid prices for index and single stock structured warrants will be throughout the day."
            }
        }
    },
    {
        path: "/id/WarrantOfTheDay",
        name: "WarrantOfTheDay",
        component: () =>
            import("../views/WarrantOfTheDay/WarrantOfTheDay.vue"),
        meta: {
            title: "Warrants of the Day",
            content: {
                keywords: "top pick warrants, trending warrants, hot warrants",
                description: "Mengetahui Waran Terstruktur apa yang sedang menjadi daya tarik investor"
            }
        }
    },
    {
        path: "/id/WarrantSearch",
        name: "WarrantSearch",
        component: () =>
            import("../views/WarrantSearch/WarrantSearch.vue"),
        meta: {
            title: "Warrant Search",
            content: {
                keywords: "warrant search, investment ideas, investment criteria, Malaysia warrants, RHB, Ambank, Kenanga, Macquarie, CIMB, Maybank, Hong Kong warrants, Hong Kong warrants, Warrant Price, Traded Volume, Market Information, Warrant Information",
                description: "Portal pencarian Waran Terstruktur yang dikeluarkan oleh PT RHB Sekuritas Indonesia"
            }
        }
    },
    {
        path: "/id/WarrantShout-Out",
        name: "WarrantShout-Out",
        component: () =>
            import("../views/WarrantShout-Out/WarrantShout-Out.vue"),
        meta: {
            title: "Warrant Shout-Out",
            content: {
                keywords: "announcement, shout-out, RHB, malaysia warrants, Sold Out, RHB Warrant",
                description: "Apa yang sedang terjadi di dunia Waran Terstruktur?"
            }
        }
    },
    {
        path: "/id/Gallery",
        name: "Gallery",
        component: () =>
            import("../views/Gallery/Gallery.vue"),
        meta: {
            title: "Gallery",
            content: {
                keywords: "Photos, Videos, RHB, malaysia warrants, RHB Warrant",
                description: "Tapak tilas akan semua hal yang telah dilalui dan di jalankan oleh tim Waran Terstruktur selama ini"
            }
        }
    },
    {
        path: "/id/Campaign",
        name: "Campaign",
        component: () =>
            import("../views/Campaign/Campaign.vue"),
        meta: {
            title: "Campaign",
            content: {
                keywords: "Campaign, Malaysia Warrants, RHB",
                description: "Apa yang sedang terjadi di perdagangan Waran Terstruktur?"
            }
        }
    },
    {
        path: "/id/WarrantCalculator",
        name: "WarrantCalculator",
        component: () =>
            import("../views/WarrantCalculator/WarrantCalculator.vue"),
        meta: {
            title: "Warrant Calculator",
            content: {
                keywords: "warrant calculator, warrant terms, RHB, malaysia warrants, Ambank, Kenanga, Macquarie, CIMB, Maybank, Expiry, Cash settlement, Hong Kong warrants, Simulation, TP, Take Profit, Target price, ",
                description: "Melakukan estimasi perhitungan harga wajar dari suatu Waran Terstruktur, serta dapat membandingkannya dengan Waran Terstruktur lainnya"
            }
        }
    },
    {
        path: "/id/Contactus",
        name: "Contactus",
        component: () =>
            import("../views/Contactus.vue"),
        meta: {
            title: "Contact Us",
            content: {
                keywords: "contact us, email, phone, Call, Feedback, RHB Warrant",
                description: "Silahkan kontak kami untuk mendapatkan informasi lebih lanjut"
            }
        }
    },
    {
        path: "/id/Disclaimer",
        name: "Disclaimer",
        component: () =>
            import("../views/Disclaimer.vue"),
        meta: {
            title: "Disclaimer"
        }
    },
    {
        path: "/id/Maintance",
        name: "Maintance",
        component: () =>
            import("../views/Maintance.vue"),
        meta: {
            title: "RHB Warrants - Home"
        }
    },
    {
        path: "/id/PersonalActPDPA",
        name: "PersonalActPDPA",
        component: () =>
            import("../views/PersonalActPDPA.vue"),
        meta: {
            title: "Personal Act"
        }
    },
    {
        path: "/id/WebinarTerms",
        name: "WebinarTerms",
        component: () =>
            import("../views/WebinarTerms.vue"),
        meta: {
            title: "Webinar terms & conditions"
        }
    },
    {
        path: "/id/Video",
        name: "Video",
        component: () =>
            import("../views/SuccessSpeaks/SuccessSpeaks.vue"),
        meta: {
            title: "Video",
            content: {
                keywords: "",
                description: "Media informasi yang dapat diakses oleh seluruh investor secara gratis"
            }

        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;