<template>
  <div id="app">
    <Header style="border: none"></Header>
    <div id="content" style="overflow: hidden" v-if="isRouterAlive">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
export default {
  name: "App",
  created() {
    this.$nextTick(function () {
      this.$js.ScreenMonitor();
      $("html").click(() => {
        $(".languageUl").hide();
      });
    });
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true, //控制视图是否显示的变量
    };
  },
  watch: {
    $route: {
      handler: "routeCue",
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    routeCue: function (val, oldVal) {
      if (val.name == "Home") {
        let scriptInfo = document.createElement("script");
        scriptInfo.type = "text/javascript";
        scriptInfo.setAttribute("async", "async");
        scriptInfo.setAttribute("data-usrc", "data-usrc");
        scriptInfo.src = "https://cdn2.woxo.tech/a.js#61de92687931d70016829718";
        document.head.appendChild(scriptInfo);
      } else {
        let callScript = document.querySelector("script[data-usrc='data-usrc']");
        if (callScript) {
          document.head.removeChild(callScript);
        }
      }
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style>
#content {
  margin-top: 80px;
}
</style>
