function headerIncident() {
  // localStorage.removeItem("alertbar");
  var scrolled = $(window).scrollTop();
  // if (localStorage.getItem("alertbar")) {
  //   $('.header-hint').hide()
  // }
  // 判断页面是否在顶部
  if (scrolled === 0) {
    // $("#navbarHead").removeClass("fixed-top");
    // $(".header-box").removeClass("fixed-top");
  } else {
    // $("#navbarHead").addClass("fixed-top");
    // $(".header-box").addClass("fixed-top");
  }

  $(window).scroll(function () {
    scrolled = $(window).scrollTop();
    if (scrolled === 0) {
      // $("#navbarHead").removeClass("fixed-top");
      // $(".header-box").removeClass("fixed-top");
    } else {
      // $("#navbarHead").addClass("fixed-top");
      // $(".header-box").addClass("fixed-top");
    }
  });
  SecondNavber();
  RightNvaber();
  SecondLevel();
  HeaderHint()
}
// navber
// 头部导航栏 Button消失
function HeadButtonhide() {
  $(".navbar-toggler").show();
  $(".nav_dan").hide();
  $("#HeadButton").hide();
  $("#navbarHead").css({ "flex-wrap": "nowrap" });
  $(".HongKong_but").hide();
}

// 头部导航栏 Button显示
function HeadButtonshow() {
  $(".navbar-toggler").hide();
  $(".nav_dan").show();
  $("#HeadButton").show();
  $("#navbarHead").css({ "flex-wrap": "wrap" });
  $(".HongKong_but").show();
}

// 头部导航栏 二级导航栏出现和消失
function SecondNavber() {
  $.each($(".navbar-ul li"), function (index, value) {
    $(".navbar-ul li")
      .eq(index)
      .mouseenter(function () {
        $(".navbar-ul li")
          .eq(index)
          .find(".dropdown-menu")
          .show();
      })
      .mouseleave(function () {
        $(".navbar-ul li")
          .eq(index)
          .find(".dropdown-menu")
          .hide();
      });
    $(".navbar-ul li")
      .eq(index)
      .find(".dropdown-menu")
      .mouseenter(function () {
        $(".navbar-ul li")
          .eq(index)
          .find(".dropdown-menu")
          .show();
      })
      .mouseleave(function () {
        $(".navbar-ul li")
          .eq(index)
          .find(".dropdown-menu")
          .hide();
      });
  });
}

// 右侧导航栏事件
function RightNvaber() {
  $("#closebtn").click(function () {
    if (!$(this).hasClass("is--active")) {
      $("#mint").css({ height: "100%" });
      $("#mint-nav").slideDown();
      $(this).addClass("is--active");
    } else {
      $("#mint").css({ height: "0" });
      $("#mint-nav").slideUp();
      $(this).removeClass("is--active");
    }
  });
}

// 右侧导航栏二级导航弹出
function SecondLevel() {
  var one = false;
  $("#SecondLevelBut-one").click(function () {
    $("#SecondLevel-one").slideToggle("slow");
    if (one) {
      $(".index-mint-arrows-one").css({ transform: "rotateZ(0deg)" });
      one = !one;
    } else {
      $(".index-mint-arrows-one").css({ transform: "rotateZ(180deg)" });
      one = !one;
    }
  });
  var two = false;
  $("#SecondLevelBut-two").click(function () {
    $("#SecondLevel-two").slideToggle("slow");
    if (two) {
      $(".index-mint-arrows-two").css({ transform: "rotateX(0deg)" });
      two = !two;
    } else {
      $(".index-mint-arrows-two").css({ transform: "rotateX(180deg)" });
      two = !two;
    }
  });
  var three = false;
  $("#SecondLevelBut-three").click(function () {
    $("#SecondLevel-three").slideToggle("slow");
    if (three) {
      $(".index-mint-arrows-three").css({ transform: "rotateX(0deg)" });
      three = !three;
    } else {
      $(".index-mint-arrows-three").css({ transform: "rotateX(180deg)" });
      three = !three;
    }
  });
  var four = false;
  $("#SecondLevelBut-four").click(function () {
    $("#SecondLevel-four").slideToggle("slow");
    if (four) {
      $(".index-mint-arrows-four").css({ transform: "rotateX(0deg)" });
      four = !four;
    } else {
      $(".index-mint-arrows-four").css({ transform: "rotateX(180deg)" });
      four = !four;
    }
  });
  var five = false;
  $("#SecondLevelBut-five").click(function () {
    $("#SecondLevel-five").slideToggle("slow");
    if (five) {
      $(".index-mint-arrows-five").css({ transform: "rotateX(0deg)" });
      five = !five;
    } else {
      $(".index-mint-arrows-five").css({ transform: "rotateX(180deg)" });
      five = !five;
    }
  });
}


// 头部提示
function HeaderHint() {
  var setNum = 0
  setInterval(function () {
    setNum++
    if (setNum % 2 === 0) {
      $('.header-hint').find('p').find('a').fadeIn();
    } else {
      $('.header-hint').find('p').find('a').fadeOut();
    }
  }, 900)

  $("#hintBut").click(function () {
    $('.header-hint').hide()
    localStorage.setItem("alertbar", true);
    $('.theadClone').css({
      'top': $(".header-box").innerHeight()
    })
    $('#content').css({
      'margin-top': $('.header-box').height()
    })
  })
}

export { headerIncident, HeadButtonhide, HeadButtonshow };
