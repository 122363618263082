<template>
  <!-- Footer -->
  <footer class="page-footer font-small indigo" style="background-color: #fff">
    <!-- Footer Links -->
    <div
      class="pt-4 pb-5 font_cand_R container-fluid mobile_fluid"
      style="background-color: rgba(242, 242, 242, 1)"
    >
      <div class="mobileC">
        <!-- Grid row -->
        <div class="row" id="showFooter">
          <div class="col-md mx-auto" style="padding: 0">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-1 mb-1 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">{{
                $t("m.toolbox").toUpperCase()
              }}</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantMatrix"
                >{{ $t("m.WarrantMatrix") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantSearch"
                >{{ $t("m.warrantSearch") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantDetails"
              >
                {{ $t("m.warrantDetails") }}
              </router-link>
            </h6>

            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantCalculator"
                >{{ $t("m.warrantCalculator") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/UnderlyingChart"
                v-html="$t('m.underlyingChart')"
              >
              </router-link>
            </h6>
          </div>
          <div class="col-md mx-auto" style="padding: 0">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-1 mb-1 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">{{
                $t("m.ideas").toUpperCase()
              }}</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantInFocus"
                >{{ $t("m.warrantinFocus") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantOfTheDay"
                >{{ $t("m.warrantsoftheDay") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/MarketMovers"
                >{{ $t("m.marketMovers") }}</router-link
              >
            </h6>
            <!-- <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/HongKongStockWarrants"
                >{{ $t("m.hongKongStockWarrants") }}</router-link
              >
            </h6> -->
          </div>
          <div class="col-md mx-auto" style="padding: 0">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-1 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">{{
                $t("m.workshop").toUpperCase()
              }}</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/LearnWarrants"
                >{{ $t("m.learnWarrants") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/Seminar">{{
                $t("m.seminar")
              }}</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/Campaign">{{
                $t("m.campaign")
              }}</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/Gallery">{{
                $t("m.gallery")
              }}</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/Video">{{
                $t("m.video")
              }}</router-link>
            </h6>
            <!-- <h6 class="font-weight-bold text-capitalize footer-h" style="margin-top: 40px;">
                        <a href="../id/disclaimer" class="footer-bold-a">Disclaimer</a>
            </h6>-->
          </div>
          <div class="col-md mx-auto" style="padding: 0">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-1 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">{{
                $t("m.announcement").toUpperCase()
              }}</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantShout-Out"
                >{{ $t("m.warrantShoutOut") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/NewWarrants"
                >{{ $t("m.newWarrants") }}
              </router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/ExpiringWarrants"
                >{{ $t("m.expiringWarrants") }}</router-link
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link
                class="footer-light-a font_cand"
                to="/id/WarrantDetails"
                >{{ $t("m.warrantDocuments") }}</router-link
              >
            </h6>
          </div>
          <div class="col-md mx-auto" style="padding: 0">
            <!-- Links -->
            <h6 class="font-weight-bold text-capitalize mt-1 footer-h">
              <a href="javascript:;" class="footer-bold-a a_default">{{
                $t("m.connect").toUpperCase()
              }}</a>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/SignMeUp">{{
                $t("m.signMeUp")
              }}</router-link>
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#RhbModal"
                class="footer-light-a font_cand"
                >{{ $t("m.rhbTradesmart") }}</a
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/Contactus"
                >{{ $t("m.contactUs") }}
              </router-link>
            </h6>
            <!-- <h6 class="font-weight-light text-capitalize mt-3 mb-3 footer-h">
              <router-link class="footer-light-a font_cand" to="/id/AntGroup">ANT GROUP</router-link>
            </h6> -->
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <a
                class="footer-light-a font_cand"
                href="javascript:;"
                data-toggle="modal"
                data-target="#Instagram"
                >{{ $t("m.instagram") }}</a
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <a
                class="footer-light-a font_cand"
                href="javascript:;"
                data-toggle="modal"
                data-target="#Telegram"
                >{{ $t("m.telegramChannel") }}</a
              >
            </h6>
            <h6 class="font-weight-light text-capitalize mt-1 mb-1 footer-h">
              <a
                class="footer-light-a font_cand"
                href="javascript:;"
                data-toggle="modal"
                data-target="#TikTok"
                >{{ $t("m.TikTok") }}</a
              >
            </h6>
          </div>
        </div>
        <!-- Grid row -->

        <!-- 屏幕小于768px -->
        <div id="hideFooter" class="font_cand_R" style="display: none">
          <!-- Blank -->
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-one">
                {{ $t("m.toolbox").toUpperCase() }}
                <span class="footer-arrows">
                  <span
                    class="bi bi-chevron-down"
                    aria-hidden="true"
                  ></span>
                </span>
              </a>
              <ul
                class="navbar-nav nav-ul"
                style="display: none"
                id="FooterUl-one"
              >
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantMatrix"
                    >{{ $t("m.WarrantMatrix") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantSearch"
                    >{{ $t("m.warrantSearch") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantDetails"
                    >{{ $t("m.warrantDetails") }}</router-link
                  >
                </li>

                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantCalculator"
                    >{{ $t("m.warrantCalculator") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/UnderlyingChart"
                    v-html="$t('m.underlyingChart')"
                  ></router-link>
                </li>
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-two">
                {{ $t("m.ideas").toUpperCase() }}
                <span class="footer-arrows">
                  <span
                    class="bi bi-chevron-down"
                    aria-hidden="true"
                  ></span>
                </span>
              </a>
              <ul
                class="navbar-nav nav-ul"
                style="display: none"
                id="FooterUl-two"
              >
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantInFocus"
                  >
                    {{ $t("m.warrantinFocus") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantOfTheDay"
                    >{{ $t("m.warrantsoftheDay") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/MarketMovers"
                    >{{ $t("m.marketMovers") }}</router-link
                  >
                </li>
                <!-- <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/HongKongStockWarrants"
                    >{{ $t("m.hongKongStockWarrants") }}</router-link
                  >
                </li> -->
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-three">
                {{ $t("m.workshop").toUpperCase() }}
                <span class="footer-arrows">
                  <span
                    class="bi bi-chevron-down"
                    aria-hidden="true"
                  ></span>
                </span>
              </a>
              <ul
                class="navbar-nav nav-ul"
                style="display: none"
                id="FooterUl-three"
              >
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/LearnWarrants"
                  >
                    {{ $t("m.learnWarrants") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/Seminar"
                    >{{ $t("m.seminar") }}
                  </router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/Campaign"
                    >{{ $t("m.campaign") }}
                  </router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/Gallery"
                    >{{ $t("m.gallery") }}
                  </router-link>
                </li>
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/Video"
                    >{{ $t("m.video") }}
                  </router-link>
                </li>
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-four">
                {{ $t("m.announcement").toUpperCase() }}
                <span class="footer-arrows">
                  <span
                    class="bi bi-chevron-down"
                    aria-hidden="true"
                  ></span>
                </span>
              </a>
              <ul
                class="navbar-nav nav-ul"
                style="display: none"
                id="FooterUl-four"
              >
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantShout-Out"
                  >
                    {{ $t("m.warrantShoutOut") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/NewWarrants"
                    >{{ $t("m.newWarrants") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/ExpiringWarrants"
                    >{{ $t("m.expiringWarrants") }}</router-link
                  >
                </li>
                <li class="nav-item nav-li">
                  <router-link
                    class="footer-light-a-two"
                    to="/id/WarrantDetails"
                    >{{ $t("m.warrantDocuments") }}</router-link
                  >
                </li>
              </ul>
            </h6>
          </div>
          <div class="mx-auto">
            <!-- Links -->
            <h6
              class="font-weight-bold footer-h mt-3 mb-3"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              style="text-align: left"
            >
              <a href="javascript:;" class="footer-bold-a" id="Footerbut-five">
                {{ $t("m.connect").toUpperCase() }}
                <span class="footer-arrows">
                  <span
                    class="bi bi-chevron-down"
                    aria-hidden="true"
                  ></span>
                </span>
              </a>
              <ul
                class="navbar-nav nav-ul"
                style="display: none"
                id="FooterUl-five"
              >
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/SignMeUp">
                    {{ $t("m.signMeUp") }}
                  </router-link>
                </li>
                <li class="nav-item nav-li">
                  <a
                    class="footer-light-a-two"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#RhbModal"
                    >{{ $t("m.rhbTradesmart") }}</a
                  >
                </li>
                <!-- <li class="nav-item">
                                <a class="footer-light-a-two" href="https://www.facebook.com/groups/MorningCuppa/" target="_blank">RHB Facebook Cuppa</a>
                </li>-->
                <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/Contactus"
                    >{{ $t("m.contactUs") }}
                  </router-link>
                </li>
                <!-- <li class="nav-item nav-li">
                  <router-link class="footer-light-a-two" to="/id/AntGroup">ANT GROUP</router-link>
                </li> -->
                <li class="nav-item nav-li">
                  <a
                    class="footer-light-a-two"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#Instagram"
                    >{{ $t("m.instagram") }}</a
                  >
                </li>
                <li class="nav-item nav-li">
                  <a
                    class="footer-light-a-two"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#Telegram"
                    >{{ $t("m.telegramChannel") }}</a
                  >
                </li>
                <li class="nav-item nav-li">
                  <a
                    class="footer-light-a-two"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#TikTok"
                    >{{ $t("m.TikTok") }}</a
                  >
                </li>
              </ul>
            </h6>
          </div>
          <!-- <div class="mx-auto" style="text-align: left;">
                    <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
                        <a href="https://www.rhbgroup.com/index.html" class="footer-bold-a" target="_blank">RHB
                            GROUP</a>
                    </h6>
                    <h6 class="font-weight-bold text-capitalize mt-3 mb-3 footer-h">
                        <a href="../id/disclaimer" class="footer-bold-a">DISCLAIMER</a>
                    </h6>
                 
          </div>-->
        </div>
      </div>
    </div>
    <!-- Footer Links -->
    <div style="padding: 0 15px; background-color: #f2f2f2">
      <div class="footer_brand">
        <div
          style="
            border-bottom: 1px solid #a0a0a0;
            padding-bottom: 20px;
            justify-content: space-between;
            align-items: center;
          "
          class="row"
        >
          <span> {{ $t("m.rightsreserved") }}</span>
          <div style="margin-top: 10px" class="footer_img">
            <p class="footer_img_text">{{ $t("m.PRHBSIn") }}</p>
            <div class="row footer_img_box">
              <a href="javascript:;" style="margin: 10px 10px 10px 0">
                <img
                  src="../../../public/image/OJK.png"
                  alt=""
                  style="width: 120px"
                />
              </a>
              <a href="javascript:;" style="margin: 0 10px 0 0">
                <img
                  src="../../../public/image/idx.png"
                  alt=""
                  style="width: 75px"
                />
              </a>
              <a href="javascript:;" style="margin: 0 0 0 0">
                <img
                  src="../../../public/image/Inklusi.png"
                  alt=""
                  style="width: 100px"
                />
              </a>

              <a href="javascript:;" style="margin: 0 0 0 10px">
                <img
                  src="../../../public/image/Kampanye.png"
                  alt=""
                  style="width: 50px"
                />
              </a>
            </div>
          </div>
        </div>
        <div style="padding-top: 20px">
          <span>
            <a
              href="https://www.rhbgroup.com/index.html"
              class="footer-bold-a"
              target="_blank"
            >
              {{ $t("m.rhbGroup") }}</a
            >
          </span>
          <span>
            <a
              href="https://www.rhbtradesmart.co.id"
              class="footer-bold-a"
              target="_blank"
            >
              {{ $t("m.RHBSI") }}</a
            >
          </span>
          <span>
            <router-link class="footer-bold-a" to="/id/Disclaimer">{{
              $t("m.disclaimer")
            }}</router-link>
          </span>

          <!-- <span>
                        <router-link class="footer-bold-a" to="/id/WebinarTerms">Webinar Terms & Conditions</router-link>
                        <a href="https://warrant.rhbgroup.com/rhbresources/pdf/Warrant Webinar TC - FINAL 27.10.2020.pdf"
                            target="_blank">{{ $t("m.webinarTerms") }}</a>
                    </span> -->
        </div>
      </div>
    </div>

    <!-- </div> -->
  </footer>
</template>

<script>
import { FooterClick } from "./Footer.js";
export default {
  name: "Footer",
  created() {},
  mounted() {
    this.$nextTick(function() {
      FooterClick();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import './Footer.css';
</style>
