// 屏幕缩到768时 去掉右对齐
function TakeOutFlushRight() {
  $("#footerLogo").removeClass("text-right");
  $("#footerLogo").addClass("text-left");
}

// 屏幕大于768时  添加右对齐
function AddFlushRight() {
  $("#footerLogo").removeClass("text-left");
  $("#footerLogo").addClass("text-right");
}

// 屏幕缩到768时 尾部导航栏的变化
function MobileChange() {
  $("#showFooter").hide();
  $("#hideFooter").show();
  $("#showFluid").hide();
  $("#hideFluid").show();

  // 头部  右侧隐藏
  $("#NavberBottom").hide();
}

// 屏幕大于768时 尾部导航栏的变化
function PCChange() {
  $("#showFooter").show();
  $("#hideFooter").hide();
  $("#showFluid").show();
  $("#hideFluid").hide();
  // 头部  右侧显示
  $("#NavberBottom").show();
}

// 屏幕缩到768时 点击事件
function FooterClick() {
  var Fone = false;
  $("#Footerbut-one").click(function() {
    $("#FooterUl-one").slideToggle("slow");
    if (Fone) {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(0deg)" });
      Fone = !Fone;
    } else {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(180deg)" });
      Fone = !Fone;
    }
  });
  var Ftwo = false;
  $("#Footerbut-two").click(function() {
    $("#FooterUl-two").slideToggle("slow");
    if (Ftwo) {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(0deg)" });
      Ftwo = !Ftwo;
    } else {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(180deg)" });
      Ftwo = !Ftwo;
    }
  });
  var Fthree = false;
  $("#Footerbut-three").click(function() {
    $("#FooterUl-three").slideToggle("slow");
    if (Fthree) {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(0deg)" });
      Fthree = !Fthree;
    } else {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(180deg)" });
      Fthree = !Fthree;
    }
  });
  var Ffour = false;
  $("#Footerbut-four").click(function() {
    $("#FooterUl-four").slideToggle("slow");
    if (Fone) {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(0deg)" });
      Ffour = !Ffour;
    } else {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(180deg)" });
      Ffour = !Ffour;
    }
  });
  var Ffive = false;
  $("#Footerbut-five").click(function() {
    $("#FooterUl-five").slideToggle("slow");
    if (Ffive) {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(0deg)" });
      Ffive = !Ffive;
    } else {
      $(this)
        .find(".footer-arrows")
        .css({ transform: "rotateZ(180deg)" });
      Ffive = !Ffive;
    }
  });
}

export {
  FooterClick,
  PCChange,
  MobileChange,
  AddFlushRight,
  TakeOutFlushRight
};
